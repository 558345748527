/** @jsx jsx */
import { jsx, Box, Text, Grid } from 'theme-ui'
import { keyframes } from '@emotion/core'
import Layout from "../components/layout"
import Link from '../components/Link'
import WavyLink from '../components/WavyLink'
import Image from '../components/Image'
import SEO from '../components/seo'

export default () => 
  <Layout location="/">
    <SEO
      keywords={['design', 'product designer', 'interface designer']} title='Home'
    />
    <Box sx={{mb: [8, 9, 12]}}>
      <Text as='h1' variant='h1' sx={{maxWidth: '20em', mb: 6}}>
        Product designer <span sx={{whiteSpace: 'nowrap'}}>incl<span className='incline'>i</span><span className='incline'>n</span><span className='incline'>e</span><span className='incline'>d</span></span> to front-end.<br/>Most recently, I worked at&nbsp;the largest print&nbsp;&&nbsp;OOH <WavyLink blank to="/specle/">ad&nbsp;delivery service</WavyLink> in&nbsp;the&nbsp;UK.
      </Text>
      <Text variant='h4' sx={{maxWidth: '800px'}}>
        Before that, I designed mobile apps for one of the largest Russian <span sx={{whiteSpace: 'nowrap'}}>e-commerce</span> businesses, helped <WundermanLogo/> <WavyLink blank href="https://www.actis.ru/">Wunderman</WavyLink>’s clients — Microsoft, Chevrolet, Yandex, etc. — reach a new audience, and had fun creating <WavyLink blank to="/simplify/">an&nbsp;award-winning music app</WavyLink> for&nbsp;macOS.
      </Text>
    </Box>

    <Grid sx={{
      gridGap: [2, 4, 6],
      gridTemplateColumns: ['unset', null, 'repeat(2, 2fr)']}
      }>
      <Project href="/kupivip/" title="KUPIVIP" bg="#bdaab8" imgSrc="kupivip/main.png" large />
      <Project href="/specle/" title="Specle" bg="#AAB2BD" imgSrc="specle/main.png" />
      <Project href="/murphy/" title="English Grammar in Use" bg="#bdb3aa" imgSrc="murphy/main.png" />
      <Project href="/simplify/" title="Simplify for Mac" bg="#bdaab8" imgSrc="simplify/main.png" large dead />
    </Grid>
  </Layout>

const ghost = keyframes`
  from {
    transform: translateY(.2em);
  }
  to {
    transform: translateY(-.1em);
  }
`

const Project = ({href, title, bg, imgSrc, dead, large}) => {
  return (
    <Link to={href} key={href} sx={{position: "relative", textDecoration: 'none', mb: 4, gridColumn: large ? '1/-1' : ['1/-1', null, null, 'span 1'], '&:hover img, &:focus img': {transform: 'scale(1.02)'}}} >
      <Image fadeIn={false} src={imgSrc} sx={{boxShadow: 1, bg: bg, borderRadius: 4, mb: 2}} imgStyle={{transition: "transform .2s ease-in"}} />
      <Text variant='h4' sx={{color: 'text', ml: 3}}>
        {title} {!!dead && <GhostIcon />}
      </Text>
    </Link>
  )
}

const GhostIcon = (props) => (
  <svg width=".8em" height=".8em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{color: 'rgba(34, 32, 30, 0.68)', alignSelf: 'center', ml: 2, animation: `2s ease-in-out infinite alternate ${ghost}`, opacity: .2}} alt="Boo! The project is dead now." >
<path fillRule="evenodd" clipRule="evenodd" d="M31.3117 31.066C29.6802 31.7609 27.8172 31.669 26.2667 30.8247L25.5088 30.4112C23.5995 29.3717 21.2622 29.521 19.5034 30.7903L19.3298 30.9166C17.328 32.3582 14.6608 32.3582 12.6937 30.9339C12.6793 30.9224 12.6634 30.9109 12.6475 30.8994C12.6315 30.8879 12.6156 30.8764 12.6012 30.865C10.7961 29.5555 8.38927 29.4004 6.42796 30.4629L5.74527 30.8305C4.18895 31.6748 2.32599 31.7609 0.694461 31.066C-0.0229496 30.7616 -0.225445 29.8426 0.301042 29.2683L0.873814 28.5044C3.55832 24.9148 5.0105 20.5556 5.0105 16.0815V10.9124C5.0105 7.89716 6.24283 5.16905 8.22728 3.19332C10.2117 1.2176 12.9657 0 16.0031 0C19.0405 0 21.7887 1.22334 23.7789 3.19332C25.7691 5.1633 26.9957 7.89716 26.9957 10.9124V16.0815C26.9957 20.5556 28.4478 24.9148 31.1381 28.5044L31.7109 29.2683C32.2316 29.8426 32.0291 30.7558 31.3117 31.066ZM14 10.9198C14 12.2904 12.8807 13.4016 11.5 13.4016C10.1193 13.4016 9 12.2904 9 10.9198C9 9.54915 10.1193 8.43802 11.5 8.43802C12.8807 8.43802 14 9.54915 14 10.9198ZM20 13.8979C21.6569 13.8979 23 12.5646 23 10.9198C23 9.27502 21.6569 7.94167 20 7.94167C18.3431 7.94167 17 9.27502 17 10.9198C17 12.5646 18.3431 13.8979 20 13.8979Z" fill="currentColor"/>
</svg>)

const WundermanLogo = () => (<svg width="1.22em" height="1.25em" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{marginBottom: '-.3em', mx: '.2em'}}>
<path d="M8.2497 9C6.57484 10.33 6.01157 12.3169 4.94414 14.1111C4.10124 15.5279 3.48107 16.8912 2.94414 18.4444C2.50181 19.724 1.7522 20.9488 1.55525 22.3056C1.35516 23.684 1.4997 25.1675 1.4997 26.5556C1.4997 30.6798 3.3294 33.9474 5.80525 37.1944C7.42631 39.3204 8.3829 41.1277 10.9997 42C17.4735 44.158 25.4646 42.4226 31.5553 39.6944C36.5863 37.441 42.4997 34.3524 42.4997 28.0556C42.4997 23.8641 42.3253 19.5439 39.3608 16.25C37.5097 14.1932 35.0625 12.521 33.0553 10.5556C30.907 8.45206 27.9935 6.67767 25.4997 5C23.3407 3.54759 21.1948 2 18.4997 2C16.5204 2 15.03 2.90421 13.3608 3.94444C11.7351 4.95756 10.8207 6.42246 9.74969 7.94444C9.17519 8.76084 8.66015 8.75 7.7497 8.75" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.5 12.5C7.87749 19.5004 10.4278 26.8556 13.7361 33.4722C14.9887 35.9774 15.931 38.7518 16.5139 41.375C16.7296 42.3458 16.75 41.4626 16.75 40.9861C16.75 38.8241 16.75 36.662 16.75 34.5C16.75 29.0885 16.806 23.6694 17.0139 18.2639C17.1502 14.7192 17.7698 11.1775 18.0139 7.63889C18.0938 6.48038 18.1364 4.71379 18.625 3.63889C18.929 2.97 19.1696 4.44948 19.25 4.75C19.9629 7.41394 20.6423 10.0732 21.3056 12.75C22.4167 17.2344 23.0684 21.8572 24.25 26.3056C24.9757 29.0376 25.246 32.0985 25.6944 34.8889C25.8177 35.6557 25.9547 36.4792 26 37.25C26.0278 37.7223 26.4719 38.2161 26.5 38.75C26.53 39.3208 26.6753 39.7456 26.8056 40.3056C27.2188 42.0826 27.2132 41.5533 27.5 40.0556C28.2682 36.0437 30.1156 32.2852 31 28.3056C31.7717 24.833 32.41 21.3145 33.25 17.8611C33.7052 15.9896 34.5 13.6706 34.5 11.75" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)